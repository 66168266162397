.home {
    @apply p-12 h-full max-w-7xl mx-auto;

    @screen sm {
        @apply grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 90px 1fr;
    }

    &--header {
        grid-column: 1 / span 2;
        grid-row: 1;
    }

    &--organisations {
        md-card-content {
            overflow-y: auto;
        }

        .org-avatar {
            width: 56px;
            height: 56px;
            border-radius: 100%;
            background-color: #e7e8ea;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            flex-shrink: 0;
            position: relative;

            i {
                color: #878f97;
            }

            .org-hover {
                @apply absolute p-4 bg-white;
                width: auto;
                box-shadow: 0px 4px 12px rgba(14, 15, 17, 0.15);
                top: -40px;
                left: 0;
                z-index: 10;
            }

            &:hover .org-hover {
                display: block;
            }
        }
    }

    md-card md-card-content {
        flex-grow: 1;
    }

    md-card-header i.fas {
        color: #d9dbde;
        margin-right: 8px;
    }

    .md-title {
        color: #0e0f11;
    }

    &--center {
        @apply absolute;
        width: 0px;
        height: 0px;
        background: transparent;
        top: 50%;
        left: 50%;
    }
}
