.question-body {
    align-items: center;

    .question-attribute {
        font-size: .8em;
        opacity: 0.8;
        margin-top: 3px;
        margin-left: 3px;
    }

    .slim-checkbox {
        padding-top: 0px;
    }

    .missing-attribute {
        color: rgb(255, 196, 0);
    }

    p {
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    .form-variant-chip {
        font-size: 22px;
    }
}

.question-form {
    margin-top: -10px;
    margin-bottom: -10px;

    .question-type .dropdown-menu {
        max-height: calc(100vh - 200px);
    }
}

.form-error-icon {
    color: #cb2431;
}


.form-type-config {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-question-type {
        cursor: pointer;
        outline: none;
        border: 0px;
        border-radius: 20px;
        padding: 8px 14px;

        background: rgba(0, 0, 0, 0.05);
        transition: background-color 100ms;

        .placeholder {
            color: rgba(0, 0, 0, 0.5);
            transition: color 100ms;

            &.ng-leave {
                display: none;
            }
        }

        i {
            margin-left: -4px;
            margin-right: 7px;
        }

        &.error {
            background: rgba(#cb2431, 0.15);

            &:hover {
                background: rgba(#cb2431, 0.25);

                .placeholder {
                    color: rgba(0, 0, 0, 0.6);
                }
            }

            &:active {
                background: rgba(#cb2431, 0.35);

                .placeholder {
                    color: rgba(0, 0, 0, 0.7);
                }
            }
        }

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        &:active {
            background: rgba(0, 0, 0, 0.2);
        }
    }
}

.form-checkbox {
    font-weight: normal;
    margin-bottom: 0px;
    line-height: 20px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;

    input {
        vertical-align: top;
        margin-left: 5px;
    }

    input:first-child {
        margin-left: 10px;
        margin-right: 5px;
    }
}

.form-text {
    margin: 24px 5px 0;

    &.readonly {
        margin: 6px 0;
    }

    .form-variant-chip p {
        display: inline;
    }
}

.form-select-row {
    .form-select-or {
        display: block;
        text-align: center;
    }

    .form-answers-preview {
        margin-top: 4px;
        font-size: 13px;
        font-style: italic;
    }
}

.form-row-options {
    display: flex;

    .form-row-option {
        padding: 0px 10px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.8em;
    }

    .form-dropdown-add {
        // height: 47px;
        display: block;
        margin: 0 auto;
    }
}