.form-text {
    .note-statusbar {
        display: none;
    }

    .note-editor.panel {
        box-shadow: none;
    }

    .note-editor.note-frame {
        border: 0px;
        margin-bottom: 0px;
    }

    .note-status-output {
        display: none;
    }

    .note-editing-area {

        .note-editable,
        .note-placeholder {
            padding: 1em;
            /* Default header CSS, as tailwind overwrites*/
            h1 {	
                font-size: 2em;
                font-weight: bold;
            }
            h2 { 
                font-size: 1.5em;
                font-weight: bold;
            }
            h3 {
                font-size: 1.17em;
                font-weight: bold;	
            }
            h4{
                font-weight: bold;	
            }
            h5	{
                font-size: .83em;
                font-weight: bold;	
            }
            h6	{
                font-size: .67em;
                font-weight: bold;
            }
        }
        .note-editable {
            cursor: text;
        }

        .note-editable::after {
            content: '';
            display: block;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
    }

    .note-toolbar-wrapper {
        transition: opacity 100ms ease, visibility 100ms ease;

        .btn-default {
            background: transparent;
        }
    }

    &.hide-toolbar .note-toolbar-wrapper {
        opacity: 0;
        visibility: hidden;
    }

    .note-toolbar {
        background: transparent;
        border: none;
        padding-bottom: 0px;
    }

    .form-text-readonly {
        padding: 10px;
    }
}