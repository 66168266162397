.media-item-edit-button {
    padding: 6px;
    border: 2px black;
    background-color: rgba(255,255,255,0.8);
    border-radius: 3px;
    width: 30px;
    height: 30px;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0, 0.2);

    &:hover {
        transform: translateY(-2px);
    }

    &:focus {
        outline: none;
    }
}
