.form-collection-card {

    .form-removal-text {
        font-size: 0.8em;
    }

    .form-collection-items {
        margin: 10px 0px;
        max-height: 300px;
        overflow-y: auto;

        .form-collection-items-header {
            font-size: 0.9em;
            color: darken(#FFF, 50%);
            margin-left: 10px;
            margin-bottom: 5px;
        }

        .form-collection-items-add {
            margin-top: 4px;
            padding-left: 38px;

            button {
                padding: 3px 10px;
                color: darken(#FFF, 50%);

                i {
                    margin-right: 5px;
                    font-size: 0.9em;
                }
            }
        }
    }

    .form-collection-item {
        display: flex;
        align-items: center;
        padding: 4px 8px;

        &:first-of-type {
            margin-top: 12px;
        }

        &:last-of-type {
            margin-bottom: 12px;
        }

        &.ui-sortable-helper {
            background: #FFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        }

        .form-collection-grip {
            padding: 3px 10px;
            cursor: move;
            color: darken(#FFF, 15%);
        }

        input {
            border: 0px;
            outline: none;
            background: darken(#FFF, 5%);
            border-radius: 4px;
            padding: 4px 10px;
            transition: background-color 100ms;
            width: 300px;

            &:hover,
            &:focus,
            &:active {
                background: darken(#FFF, 7%);
            }
        }

        .form-collection-remove {
            padding: 2px 10px;
            color: darken(#FFF, 50%);
            border: 0px;

            &:active,
            &:hover,
            &:focus {
                cursor: pointer;
                color: darken(#FFF, 90%);
                outline: none;
                box-shadow: none;
            }
        }

    }
}