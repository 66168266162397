@import "variables";

.button {
    color: white;
    background-color: map-get($colors, primary);
    box-shadow: $button-box-shadow;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    text-transform: none;
    transition: all .15s ease;

    &:hover {
        transform: translateY(-2px);
    }
}

.button:disabled {
    opacity: 0.5;

    &:hover {
        transform: none;
    }
}

.md-icon-button.coreo-icon-button {
    color: map-get($colors, primary);
}

.md-fab.coreo-fab {
    @extend .button;

    width: $icon-button-height;
    height: $icon-button-height;
    box-shadow: none;

    i {
        font-size: 26px !important;
    }

    &.small {
        width: $icon-button-small-height;
        height: $icon-button-small-height;

        i {
            font-size: 16px !important;
        }
    }
}

.md-button.coreo-button {
    @extend .button;

    margin: $button-margin;
    padding: $button-padding;
    min-height: $button-height;
    border-radius: $button-border-radius;
    line-height: $button-height;

    md-md-progress-circular {
        display: inline-block;

        svg {
            stroke: white;
        }
    }

    &.block {
        width: calc(100% - (2 * #{$button-margin})) !important;
    }

    &.small {
        min-height: $button-small-height;
        font-size: $button-small-font-size;
        line-height: $button-small-height;
    }

    &.mini {
        min-height: $button-mini-height;
        font-size: $button-mini-font-size;
        line-height: $button-mini-height;
        min-width: 0;
        // padding: 0;
    }

    &.round {
        padding: 0 15px;
        border-radius: 22px !important;
    }

    &.icon-right {
        i {
            margin-left: 10px;
        }
    }

    &.icon-left {
        i {
            margin-right: 10px;
        }
    }
}

.coreo-button,
.coreo-fab {

    &.secondary {
        background-color: map-get($colors, lighter);
        color: map-get($colors, dark);
        // box-shadow: none;

        i {
            color: map-get($colors, dark);
        }
    }

    &.clear {
        background-color: rgba(0, 0, 0, 0);
        color: map-get($colors, primary);
        box-shadow: none;
    }

    &.outline {
        color: #3E4247;
        background-color: transparent;
        border: 1px solid #D9DBDE;
        border-radius: 6px;
        box-shadow: none;
    }

    &.danger {
        background-color: map-get($colors, red);
        color: white;
    }

    &.danger.clear {
        background-color: rgba(0, 0, 0, 0);
        color: map-get($colors, red);
    }

    &.danger.outline {
        @apply bg-white text-danger border-danger border;

    }

    &.facebook {
        background-color: map-get($colors, facebook);
    }

    &.google {
        background-color: map-get($colors, google);
    }
}

button.coreo-button {
    @apply bg-primary;
    @apply rounded-md;
    @apply text-white;
}