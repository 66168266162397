@import "variables";

@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/darcula.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~angular-toastr/dist/angular-toastr.css";
@import "~angular-bootstrap-lightbox/dist/angular-bootstrap-lightbox.css";
@import "~angularjs-color-picker/dist/angularjs-color-picker.css";
@import "~angularjs-color-picker/dist/themes/angularjs-color-picker-bootstrap.min.css";
@import "~bootstrap-iconpicker/bootstrap-iconpicker/css/bootstrap-iconpicker.css";
@import "~angular-material/angular-material.css";
@import "~summernote/dist/summernote.css";
@import "~basscss/css/basscss.css";
@import "~ionicons/dist/css/ionicons.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "~@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
@import "~ng-sortable/dist/ng-sortable.min.css";
@import "../assets/AdminLTE.css";

@import "./typography.scss";
@import "./tables.scss";
@import "./buttons.scss";
@import "./cards.scss";
@import "./menus.scss";
@import "./modals.scss";
@import "./forms.scss";
@import "./lists.scss";
@import "./chips.scss";
@import "./tooltips.scss";

@import "../layouts//unauthenticated.layout.scss";
@import "../layouts/intermediarypage.layout.scss";
@import "../layouts/app.layout.scss";
@import "../layouts/project.layout.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Core Sans C";
    src: url("../assets/fonts/coresansc-35light.woff") format("woff"),
        url("../assets/fonts/coresansc-35light.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Core Sans C";
    src: url("../assets/fonts/coresansc-45regular.woff") format("woff"),
        url("../assets/fonts/coresansc-45regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Core Sans C";
    src: url("../assets/fonts/coresansc-65bold.woff") format("woff"),
        url("../assets/fonts/coresansc-65bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
}

body,
input,
textarea,
select,
button {
    font-family: "Core Sans C", Roboto, Helvetica Neue, sans-serif;
}

* {
    outline: none;
}

.selectable {
    user-select: text;
}

.col-md-3.padding-none,
.col-md-4.padding-none,
.col-md-8.padding-none,
.col-md-9.padding-none,
.col-md-12.padding-none {
    padding: 0px !important;
}

.row {
    margin: 0px;
}

.toolbar-clear {
    background-color: transparent;
}

md-radio-button.md-checked .md-on {
    background-color: #0069df;
}
