project-records-map {
    .button-control {
        position: absolute;
        z-index: 9999;
        background-size: cover;
        box-shadow: rgba(96, 96, 96, 0.15) 0px 2px 10px;
        border-radius: 4px;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        border: 0;
        background-color: #fff;

        &.streets {
            background-image: url(../../../assets/images/satellite.png);
            color: white;
        }

        &.satellite {
            background-image: url(../../../assets/images/streets.png);
            color: #000;
        }

        &.top-right {
            bottom: initial;
            top: 12px;
            z-index: 100;
        }

        &.drawactive {
            @apply bg-primary text-white;
        }

        &.delete {
            @apply bg-danger;
        }
    }

    .button-selection {
        @apply absolute bg-white rounded shadow-sm z-50 p-2 font-bold text-danger border-danger border outline-none;
        left: 11px;
        bottom: 10px;
    }

    &.sideopen .button-selection {
        left: 331px;
    }

    &.disabled {
        @apply opacity-30 pointer-events-none;
    }
}
