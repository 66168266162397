@import "../../theme/variables";

app-header {
    .main-header {
        width: 100%;
        height: $projectHeaderHeight;
        background: white;
        border-bottom: 1px solid map-get($colors, lighter);
        z-index: 997;
    }

    .coreo-logo {
        height: $projectHeaderHeight;
        padding: calc((#{$projectHeaderHeight} - 40px) / 2);
        padding-left: 1rem;
        border-bottom: 1px solid #e6ecf3;
    }

    .navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .spinner {
        width: 2em;
        height: 2em;
    }

    app-breadcrumbs {
        flex: 1 0 auto;
        height: $projectHeaderHeight;
        padding-left: 15px;
        color: map-get($colors, medium);
        font-size: 14px;
        font-weight: 600;
        line-height: $projectHeaderHeight;
    }

    .dropdown-toggle {
        color: map-get($colors, dark);
        text-decoration: none;
    }

    .user-image {
        width: 32px;
        height: 32px;
        margin-right: 4px;
        border-radius: 50%;
    }

    .menu-button {
        text-transform: none;
        height: 32px;
        padding: 0 1rem 0 0;
        min-width: 0;
        transition: all .15s ease;

        .caret {
            color: rgb(51, 51, 51);
            opacity: 0.25;
        }

        &:hover {
            background-color: transparent !important;
            transform: translateY(-2px);
        }
    }
}

.user-menu{
    md-menu-item img, md-menu-item md-icon{
        @apply w-6 mr-4 text-grey-50;
    }
    md-menu-item > a {
        @apply flex items-center justify-start pt-0;
    }
}
.user-menu--container{
    @apply text-grey-100 flex items-center text-sm w-full;
}
.user-menu--image{
    flex-basis: 52px;
    @apply flex items-center justify-center;
}

.app-help-menu-item {
    button,a{
        @apply text-grey-100 text-sm flex items-center;
    }

    md-icon {
        @apply text-lg text-grey-50;
    }
}

#app-help {
    @apply text-primary normal-case font-semibold border-primary rounded-md border border-solid;
    min-height: 32px;
    max-height: 32px;
    line-height: 32px;
}
