.record-map {
    width: 100%;
    height: 100%;

    .button-control {
        position: absolute;
        bottom: 24px;
        right: 12px;
        z-index: 9999;
        background-size: cover;
        box-shadow: rgba(96, 96, 96, 0.15) 0px 2px 10px;
        border-radius: 4px;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        background-image: url(../../assets/images/satellite.png);
        color: white;
        border: 0;

        &.satellite {
            background-image: url(../../assets/images/streets.png);
            color: #000;
        }
    }
}
