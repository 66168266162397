@import "../theme/variables";

html {
    font-size: 16px;
}

@font-face {
    font-family: "CoreSans-semi";
    src: url("../assets/webfonts/Core_semi_2_0.eot");
    src: url("../assets/webfonts/Core_semi_2_0.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/webfonts/Core_semi_2_0.woff2") format("woff2"),
        url("../assets/webfonts/Core_semi_2_0.woff") format("woff"),
        url("../assets/webfonts/Core_semi_2_0.ttf") format("truetype");
}

@font-face {
    font-family: "CoreSans";
    src: url("../assets/webfonts/Core_0_0.eot");
    src: url("../assets/webfonts/Core_0_0.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/webfonts/Core_0_0.woff2") format("woff2"),
        url("../assets/webfonts/Core_0_0.woff") format("woff"),
        url("../assets/webfonts/Core_0_0.ttf") format("truetype");
}

.largefont {
    font-size: 1.25rem;
    line-height: 1.6em;
}

@media (min-width: 320px) {
    .largefont {
        font-size: calc(1.25rem + 0.625vw + -2px);
    }
}

@media (min-width: 960px) {
    .largefont {
        font-size: calc(1.25rem + 4px);
    }
}

.user-panel {
    min-height: 60px;
}

.user-panel > .info {
    left: 0;
}

.hover-cursor {
    cursor: pointer;
}

[ui-sref] {
    cursor: pointer;
}

// .col-md-6 table.dataTable {
//     table-layout: fixed;
// }
.col-lg-0,
.col-md-0,
.col-sm-0 {
    width: 0;
}

.animate-width {
    transition: width 0.3s;
}

.pagination > li > a.active {
    background-color: #3c8dbc;
    color: #fff;
}

.table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    -webkit-overflow-scrolling: touch;
}

.content-header h1 img {
    max-width: 50px;
    max-height: 50px;
}

.list-group.list-group-condensed {
    li {
        padding: 6px 10px;
    }
}

.widget-user-header img {
    position: absolute;
    width: 100px;
    height: 100%;
    left: 20px;
    top: 20px;
    bottom: 20px;
    max-height: 85px;
}

.verification-label {
    min-width: 80px;
}

.label-clear {
    background-color: #fff;
    color: #444;
    border: 1px solid #eee;
}

.angular-google-map-container {
    height: 300px;
}

.box-body > table > tbody > tr:first-child > td,
.box-body > table > tbody > tr:first-child > th {
    border-top: 0;
    border-width: 0;
}

li.dropdown {
    cursor: pointer;
}

@media (max-width: 768px) {
    .skin-blue .main-header .navbar .dropdown-menu li a {
        color: #000;
    }
}

@media (max-width: 1024px) {
    .navbar-collapse.pull-left + .navbar-custom-menu {
        right: 0;
    }
}

/* Login Page */

.login-box {
    margin-bottom: $content-padding;
}

.login-box-body {
    border-radius: $modal-border-radius;
    box-shadow: $modal-box-shadow;

    .forgot-password {
        margin: 16px 0;
    }

    .forgot-password,
    .login-signup {
        text-align: center;

        a {
            color: map-get($colors, primary);
        }
    }

    .text-divider {
        margin: 20px 0;
    }
}

.clickable {
    cursor: pointer;
}

body,
body.wrapper {
    height: 100%;
}

body > .wrapper > div[ui-view] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    > div.content-wrapper {
        background-color: map-get($colors, background);
        flex-grow: 1;
        display: flex;

        > div[ui-view] {
            flex-grow: 1;
        }
    }
}

#loading-bar .bar {
    position: relative;
}

// Fix for position:sticky;
.wrapper {
    overflow: visible;
}

.spinner {
    vertical-align: middle;
    display: inline-block;
    height: 1.2em;
    width: 1.2em;
    border: 0.15em solid transparent;
    border-top: 0.15em solid #111;
    border-radius: 1em;
    animation: spin 0.5s linear infinite;
    margin-right: 10px;

    &.spinner-white {
        border-top-color: #fff;
    }
}

i.fa-spinner {
    animation: spin 0.8s linear infinite;
}

.control-information {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: left;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

ul.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    list-style: none;
    border-radius: 0.25rem;

    .divider {
        padding: 0 5px;
        color: #ccc;
    }
}

.panel-heading h4 {
    display: inline-block;
}

.modal-body textarea.form-control {
    max-width: 100%;
    min-width: 100%;
}

.text-divider {
    position: relative;
    overflow: hidden;
}

// .text-divider:before,
// .text-divider:after {
//     position: absolute;
//     content: '\a0';
//     background-color: #ccc;
//     width: 45%;
//     top: 51%;
//     height: 1px;
//     overflow: hidden;
// }
// .text-divider:before {
//     margin-left: -50%;
//     text-align: right;
// }
.text-divider {
    text-align: center;

    span {
        display: inline-block;
        position: relative;

        &:before,
        &:after {
            content: "";
            position: absolute;
            height: 1px;
            border: 1px solid map-get($colors, lighter);
            top: 50%;
            width: 600px;
        }

        &:before {
            right: 100%;
            margin-right: 15px;
        }

        &:after {
            left: 100%;
            margin-left: 15px;
        }
    }
}

.toast {
    @apply font-bold;
}

.toast-success {
    @apply bg-success;
}

.toast-active {
    // background-image: url(...) !important;
    background-color: #f39c14;

    .spinner {
        position: absolute;
        left: 12px;
        top: 19px;
        height: 1.5em;
        width: 1.5em;
        font-size: 1.5em;
    }
}

.toast-message {
    .btn-default {
        color: #333;
    }

    .btn-full {
        margin-top: 8px;
    }
}

.panel.no-border {
    border: none;
}

.list-group-unbordered li:first-child {
    border-top: 0;
    padding-top: 0;
}

.page-content .container-fluid {
    margin-right: 0;
    margin-left: 0;
}

.md-autocomplete-suggestions-container,
.md-select-menu-container {
    z-index: 99999999 !important;
}

.md-open-menu-container,
.md-dialog-container,
md-backdrop.md-menu-backdrop,
.md-datepicker-calendar-pane {
    z-index: 999 !important;
}

.md-autocomplete-suggestions,
.md-datepicker-calendar-pane {
    background: white;
}

.md-datepicker-input-container {
    border: 0;
}

.md-calendar-date-today span {
    @apply rounded-full border-grey-15 border;
}

.md-calendar-selected-date span {
    @apply rounded-full text-white bg-primary;
}

.md-calendar-date-disabled {
    @apply opacity-40;
}

md-autocomplete-wrap {
    width: 100%;
}

.md-select-menu-container {
    z-index: 900;
}

md-backdrop.md-select-backdrop {
    z-index: 1111;
}
md-backdrop.md-dialog-backdrop {
    z-index: 1100 !important;
    background: rgb(14, 15, 17);
}

.md-dialog-container {
    z-index: 1110 !important;
}

md-dialog-content {
    padding: 10px;
}

md-dialog {
    z-index: 999;
}

.tabulator {
    .tabulator-loader {
        .tabulator-loading.tabulator-loader-msg {
            border: 0;

            .spinner {
                color: #0051ac;
                border-top-color: #0051ac;
                width: 2em;
                height: 2em;
                margin: 0 auto;
            }
        }
    }
}

.input-feedback-dot {
    font-size: 8px;
    vertical-align: baseline;
}

md-select-menu[multiple]
    md-option.md-checkbox-enabled[selected]
    .md-icon:after {
    border-left: 0 !important;
    border-top: 0 !important;
}

md-pagination-wrapper md-tab-item {
    min-width: 0px !important;
}

.ng-hide.ng-hide-animate {
    display: none !important;
}

md-select-value {
    margin-bottom: 0px !important;
}
