.ce-paragraph--right {
  text-align: right;
}

.ce-paragraph--center {
  text-align: center;
}

.ce-paragraph--left {
  text-align: left;
}

.image-tool__caption {
  display: none;
}

.ce-delimiter:before {
  line-height: initial;
}

.image-tool__image-picture {
  display: inline !important;
}

.toolbox-icon {
  font-size: 18px;
}
