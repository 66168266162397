@import "../../../theme/variables";

project-records-filter-item {
    border: 1px solid rgba(122, 122, 122, 0.5);
    border-radius: 4px;
    // overflow: hidden;
    display: block;
    position: relative;

    &.valid {
        border-color: map-get($colors, primary);
    }

    md-select,
    md-datepicker,
    md-autocomplete {
        border: 0;
        height: 32px;
        padding: 0 6px;
    }

    md-datepicker {
        padding-right: 0;
        margin-right: 0;
    }

    md-input-container {
        margin: 0;
        padding: 0 6px 6px;

        input {
            border: none !important;
        }
    }

    .fa-times-circle {
        position: absolute;
        top: -8px;
        right: -8px;
        z-index: 9999;
        color: map-get($colors, red);
        font-size: 22px;
        background: white;
        cursor: pointer;
    }
}

.project-records-filter-item--search {
    .project-records-filter-item--search-header {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
        padding-left: 16px;
        height: 48px;
        cursor: pointer;
        position: relative;
        display: flex;
        width: auto;
    }

    md-content._md {
        max-height: 240px;
    }

    md-input-container {
        min-width: 112px;
    }

    .project-records-filter-item--searchbox {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        padding: 0;
    }
}