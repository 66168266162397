.project-record-diff-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.compare-record-attributes {
    width: 400px;
}

.compare-record-map {
    height:300px;
    width:400px;

    .mapboxgl-control-container {
        height: 300px;
        position: relative;
    }
}

.diff-arrow {
    margin: 10px; 
}