project-records-filter {
    width: 320px;
    display: block;

    project-records-filter-item {
        margin-bottom: 8px;
    }
}



.project-records-filter--container {
    padding: 8px;
}


.project-records-filter--operator-container {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.project-records-filter--operator {
    width: 52px;
    background: white;
    color: #ccc;
    padding: 4px;
    text-align: center;
    cursor: pointer;

    &.active {
        background: #0051ac;
        color: white;
    }

    &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}