@import "../../theme/variables";

verification-table-header {
  padding-top: 7px;
  margin-bottom: 0;
}

.state-modifier {
  display: inline;
}

.status-list {
  position: relative;

  :last-child {
    margin-bottom: 0 !important;
  }
}

li.project-verification-state {
  color: white;
  font-weight: bold;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  max-width: 100%;
  background-color: #d2d6de;

  button {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;

    &:hover {
      color: white;
    }
  }

  .badge {
    float: right;
    margin-right: 28px;
  }

  &:hover {
    button {
      visibility: visible;
    }
  }
}

md-switch {
  .md-thumb {
    background-color: map-get($colors, primary);
  }

  .md-thumb::after {
    display: none;
  }

  .md-bar {
    background-color: map-get($colors, light);
  }
}

md-switch.md-checked {
  .md-bar {
    background-color: map-get($colors, primary-highlight);
  }
}