record-attributes {
    .list-item-label {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        font-weight: lighter;
        opacity: 0.8;
    }

    ul.attachment-grid{
        grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
        @apply px-0;

        li img{
            @apply mx-auto
        }
    }
}
.attr-add {
    background: #ccf79e;
}

.attr-remove {
    background: #f25c5c;
}