@import '../../theme/variables';

.organisation-invitation {
    margin: 1rem auto;
    @apply max-w-screen-md relative h-screen w-full overflow-y-auto;
    background: white;
    border-radius: 8px;
    a {
        color: map-get($colors, primary);
    }

    &--header {
        text-align: center;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #E7E8EA;

        h4 {
            font-weight: 800;
            font-size: 25px;
            line-height: 37px;
            color: #0E0F11;
        }

        img {
            height: 64px;
            width: auto;
            display: block;
            margin: 0 auto;
        }
    }

    &--form {
        h4 {
            text-align: center;
            color: #3E4247;
            font-weight: 600;
            font-size: 1rem;
        }

        .coreo-button {
            margin-bottom: 1.5rem;
        }

        .error {
            color: #FC4B54;
            margin: 1rem 0;
            font-weight: bold;
        }
    }

    &--forgot {
        text-align: center;
        margin: 1rem 0;
        font-weight: bold;
        font-size: 0.75rem;
        display: block;
    }

    &--sign-in {
        color: #878F97;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
    }

    &--error {
        text-align: center;
    }
}

.org-invite-username-field {
    text-transform: lowercase;
}