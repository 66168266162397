p.message {
    white-space: pre-line;
    overflow-x: auto;
}

.message-container {
    position: relative;
    .chat .item {
        margin-bottom: 0;
    }
}

.message-container.item .message {
    margin-top: -25px;
}

.message-container.unread {
    font-weight: bold;
}

.message-attachment {
    display: block;
    padding: 4px;
}

.message-attachment:hover {
    background-color: #3c8dbc;
    color: #fff;
}

.message-check {
    position: absolute;
    left: 6px;
    top: 50px;
    padding: 6px;
    cursor: pointer;
}

.message-send-btn {
    margin-left: 8px;
}