map-menu {
    @apply text-xs shadow;

    .map-menu__base-styles {
        @apply flex;
    }
    .map-menu__base-style {
        @apply flex-1 text-center flex flex-col items-center justify-center;

        button {
            @apply w-10 h-10 shadow rounded bg-cover bg-center focus:outline-none;
            background-image: url(../../../assets/images/mapbox-outdoors-preview.png);
        }

        &.streets button{
            background-image: url(../../../assets/images/mapbox-streets-preview.png);
        }
        &.satellite button{
            background-image: url(../../../assets/images/mapbox-satellite-preview.jpeg);
        }
        &.outdoors button{
            background-image: url(../../../assets/images/mapbox-outdoors-preview.png);
        }
        &.satellite-streets button{
            background-image: url(../../../assets/images/mapbox-satellite-streets-preview.jpeg);
        }

        label{
            @apply h-8 pt-2;
        }

        &.active{
            button{
                @apply ring-4 ring-primary ring-offset-1 ring-opacity-80;
            }
        }

    }
    .map-menu__custom-layer{
        @apply flex gap-2 items-center text-sm cursor-pointer py-1;
        color: #06222d;

        i:last-of-type{
            @apply ml-auto;
        }
    }
}
