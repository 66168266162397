//Currently recycling styling from project-forms.scss

.pages-welcome-page-icon {
  opacity: 0.7;
  width: 25px;
  height: 100%;
  position: absolute;
  right: 125px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.pages-not-visible-icon {
  opacity: 0.7;
  width: 25px;
  height: 100%;
  position: absolute;
  right: 100px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}