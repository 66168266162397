@import "variables";

md-content {
    padding: $card-content-padding;
}

md-card {
    margin: $card-margin;
    background: white;
    box-shadow: $card-box-shadow;
    border-radius: $card-border-radius;
    overflow: hidden;

    md-card-content {
        padding: $card-content-padding;
        overflow-y: auto;
    }

    md-card-content.no-padding {
        padding: 0px !important;
    }

    md-card-header-text {
        display: flex;
        justify-content: center;
        padding: $card-header-text-padding;

        .card-title,
        .md-title {
            @extend .display-3;
            color: #0E0F11;
            margin: 0;

            .fas {
                margin-right: 8px;
                color: #D9DBDE;
            }
        }
    }

    md-card-header {
        padding: $card-content-padding;
        border-bottom: 2px solid map-get($colors, background);
    }

    md-card-actions {
        margin: 0px;
        padding: $modal-toolbar-padding;
        border-top: 1px solid map-get($colors, background);
    }

    .card-header-badge {
        height: 26px;
        margin: auto;
        padding: 0 10px;
        color: map-get($colors, primary);
        background: rgba(0, 105, 223, 0.1);
        border-radius: 13px;
        border: 1px solid map-get($colors, primary);
        line-height: 24px;
        font-weight: 600;
    }
}

md-tabs {
    margin: $card-margin;
    box-shadow: $card-box-shadow;
    border-radius: $card-border-radius;
    background: white;

    md-tabs-wrapper {
        border-bottom: 2px solid map-get($colors, background);
    }

    md-tabs-canvas,
    md-pagination-wrapper,
    md-tab-item {
        height: 65px;
    }

    .md-tab {
        padding: 15px 0;
        margin: 0 15px;
        font-weight: 600;
    }

    md-tab-item {
        display: flex;
        align-items: center;
        height: 100%;
        color: map-get($colors, light);
    }

    md-tab-item.md-active {
        color: map-get($colors, primary);
        border-bottom: 3px solid map-get($colors, primary);
    }
}

.index-card {
    width: $index-card-width;
    transition: all .15s ease;
    text-align: center;

    md-card-footer {
        margin: auto 0;
    }

    md-card-content.index-card-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: $index-card-width;
        height: $index-card-width;
        padding: 0px;
        border-radius: $card-border-radius $card-border-radius 0 0;
        overflow: hidden;

        img {
            display: block;
            min-width: $index-card-width;
            min-height: $index-card-width;
            object-fit: cover;
            -ms-interpolation-mode: bicubic;
        }
    }

    .index-card-title {
        margin: 0 0 5px 0;
        font-size: $index-card-title-font-size;
        font-weight: 600;
    }

    .index-card-title:last-child {
        margin: 0;
    }

    .index-card-description {
        margin: 0px;
        color: map-get($colors, medium);
        font-size: $index-card-description-font-size;
    }

    i,
    .glyphicon {
        //margin: calc((#{$index-card-width} - 2 * #{$card-content-padding} - #{$index-card-icon-size}) / 2);
        margin: 28px;
        font-size: $index-card-icon-size;
    }

    &:hover {
        box-shadow: $index-card-hover-box-shadow;
    }
}

.index-card-selected {
    box-shadow: $index-card-hover-box-shadow;
}