.form-deleted-row {
    margin: 5px 0px 5px 12px;
}

.form-deleted-missing {
    color: rgba(0, 0, 0, 0.5);
    font-style: italic;
}

.form-deleted-question {
    margin-left: 0px;
    margin-right: 25px;
    font-size: 1.1em;
}

.form-deleted-description {
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.7);
    margin-left: 5px;
}

.form-deleted-variant {
    margin-top: 5px;
}

.form-variant-chip {
    display: inline-block;
    padding: 8px 14px;
    // border: 1px solid rgba(0,0,0,0.1);
    // background: rgba(0,0,0,0.05);
    // border-radius: 20px;

    .placeholder {
        font-style: italic;
        color: rgba(0, 0, 0, 0.7);
    }

    i {
        margin-left: -4px;
        margin-right: 7px;
    }
}

.form-deleted-summary {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.7);
}