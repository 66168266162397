@import "../../../../theme/variables";

project-forms-block-editor {
    .form-group {
        padding: 6px 12px 0;

        span,
        label {
            color: #06222d;
            font-weight: normal;
        }
    }

    md-select {
        margin: 0;
    }

    md-input-container .md-input {
        // width: auto;
    }

    .form-row {
        // padding: 0px 15px 0px;
        @apply px-4;

        md-input-container {
            margin: 0;

            span.label {
                font-weight: bold;
                color: #06222d;
            }
        }

        .form-checkbox {
            @apply flex justify-between items-center;
            min-height: 36px;
        }

        .form-checkbox input {
            @apply ml-0;
        }

        span,
        label {
            color: #06222d;
            font-weight: normal;
        }
    }

    .md-button {
        min-height: 0;
    }

    .has-error .help-block {
        color: #dd4b39 !important;
    }

    .geometry-types-label {
        margin-bottom: 16px;
    }

    .question-options-checkbox {
        float: right;
    }

    md-select {
        min-width: 180px;
        text-align: center;
    }
}
