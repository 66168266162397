@import "../../../theme/variables";

$iconPadding: calc((#{$project-sidebar-width} - 26.75px)/2);

project-sidebar {

    &.open .project-sidebar-frame .project-sidebar a>span {
        opacity: 1;
    }

    &.open .project-sidebar-toggle>md-icon {
        transform: rotate(180deg);
    }

    .project-sidebar-frame {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 100%;

        .project-sidebar {
            @apply bg-primary-darken-5;
            position: relative;
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
            bottom: 0;
            overflow: hidden;

            a {
                i {
                    margin-right: 0;
                }
            }

            a>span {
                margin-left: 24px;
                opacity: 0;
                transition: opacity 0.3s ease;
            }

            img {
                width: $project-sidebar-width;
                height: $project-sidebar-width;
            }

            .content-header {
                padding: 0;
                display: flex;
                color: white;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                h1 {
                    display: flex;
                    height: $project-sidebar-width;
                    color: white;
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;

                    >* {
                        align-self: center;
                    }

                    .project-title {
                        // flex: 0 0 calc(#{$project-sidebar-width-open} - #{$project-sidebar-width} - 26px);
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }

            .nav-holder {
                display: flex;
                flex: 1 0 auto;
                flex-direction: column;
                justify-content: space-between;

                .nav>li {
                    width: $project-sidebar-width-open;
                    border-left-width: 4px;
                    border-left-style: solid;
                    @apply border-primary-darken-5;

                    &.active {
                        @apply border-secondary bg-primary;
                    }
                }

                .nav>li>a {
                    font-size: 14px;
                    font-weight: bold;
                    transition: background 150ms, color 150ms;
                }

                .nav>li> :hover {
                    color: #fff;
                    background: map-get($colors, primary-highlight);
                }

                .nav-tabs {
                    border-bottom: none;

                    >li>a {
                        margin: 0;
                        padding: 15px 15px 15px $iconPadding;
                        border: none;
                        color: rgba(255, 255, 255, 0.5);
                    }

                    >li.active>a {
                        cursor: pointer;
                        color: white;

                    }

                    >li>a>i {
                        font-size: 15px;
                    }
                }
            }

            .project-sidebar-toggle {
                position: relative;
                flex: 1;

                md-icon {
                    position: absolute;
                    bottom: 18px;
                    left: 0;
                    right: 0;
                    width: 100%;
                    color: white;
                    opacity: 0.75;
                    font-size: 15px;
                    text-align: center;
                    transition: transform 0.3s;

                }
            }
        }
    }

    .project-selector-holder {
        position: relative;
        width: 100%;
        height: $project-sidebar-width;
        background: #002146;
        cursor: pointer;

        .content-header {
            width: 100%;
        }

        .content-header i {
            width: 16px;
            height: 16px;
            padding: 0;
            text-align: center;
            transition: transform 250ms;
        }

        .dropdown-close {
            transform: rotate(180deg);
        }

        .project-image {
            margin: 0;
            padding: 6px;
            border-radius: 100%;
        }
    }

    .project-selector {
        position: fixed;
        top: $project-sidebar-width;
        left: 0;
        width: $project-sidebar-width-open + 30px;
        max-height: 30vh;
        background: white;
        box-shadow: 0 4px 15px 0 rgba(41, 49, 52, 0.50);
        border-radius: 5px;
        overflow-y: scroll;
        overflow-x: hidden;

        ul {
            padding: 6px;
        }

        li a {
            text-transform: uppercase;
            font-weight: bold;

            img {
                max-width: 30px;
                max-height: 30px;
                margin-right: 6px;
            }
        }
    }

    //TODO: Make these rules universal!
    .dropdown-menu {
        padding: 10px 5px;

        li>a {
            color: map-get($colors, medium);
        }

        li>a:hover {
            border-radius: 5px;
            background-color: map-get($colors, background);
        }

        li>a {
            padding: 5px 10px;
            font-size: 14px;
        }
    }
}