@import "../../../../theme/variables";

#geometry-styling-modal {
    height: 90%;
    max-height: 90%;

    .col-space-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 95%;
        overflow-y: auto;
    }

    md-dialog-content {
        height: 100%;
        overflow: hidden;
    }

    .mapboxgl-map {
        overflow: auto;
    }

    .geometry-type-container {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
    }

    .geometry-type {
        width: 100px;
        background: white;
        color: #ccc;
        padding: 4px;
        text-align: center;
        cursor: pointer;

        &.active {
            background: #0051ac;
            color: white;
        }

        &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .color-picker-container {
        width: 100%;
    }

    #styling-map {
        width: 400px;
        min-height: 300px;
    }

    .input-label {
        font-weight: 600;
    }

    .row {
        padding-bottom: 10px;
        padding-top: 10px;
    }
}