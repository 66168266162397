@import "../../theme/variables";

.record-content {
    max-width: 1170px;
    margin: 0 auto;
    display: block;

    h3 {
        margin-top: 0;
    }
}

section.record {
    ul {
        padding: 0 32px;
    }
}

.project-record {
    record-map {
        display: block;
    }

    record-attributes, record-attributes-form {
        display: block;
        padding: 12px;
    }

    md-card {
        margin-bottom: 16px;
    }

    project-record-state {
        width: 100%;
    }

    .record-status-list {
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    }

    .record-user {
        padding: 12px 24px;

        img {
            width: 48px;
            height: 48px;
            margin-right: 12px;
            border-radius: 50%;
        }
    }

    .project-record-admin__section {
        h3 {
            border-bottom: 1px solid map-get($colors, light);
            margin-bottom: 18px;
        }

        padding: 16px;
    }
}

.logs-unavailible-message {
    margin: 15px;
}

input[type="number"].point-edit-input-right,
input[type="number"].point-edit-input-left {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
}

.record-point-edit{
    @apply pl-2;
    input{
        @apply w-40 px-2 border rounded border-grey-50 min-h-0;
    }
}