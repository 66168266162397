.project-hooks {
    ul {
        list-style-type: disc;
        background-color: #fff;
        line-height: normal;
        padding-left: 0;

        li {
            list-style-type: none;
            position: relative;
            display: flex;
            align-items: center;
        }
    }
}

.project-hook-item {
    align-items: center;
    display: flex;
    position: relative;
    padding: 10px;
    width: 100%;
    height: 100px;

    span {
        flex: 0 0 auto;
    }

    i {
        display: block;
        height: 48px;
        line-height: 48px;
        width: 48px;
        font-size: 48px;
    }

    img {
        max-width: 80px;
        margin-right: 25px;
    }
}

.project-hook-item-description {
    flex: 1;
    display: block;
    overflow: hidden;

}

.hook-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-hook-form {
    min-width: 480px;
}