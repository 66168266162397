.record-history-container {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.record-log-description {
  padding: 12px;
  margin-left: 12px;
  border: solid 1px #ddd;
  border-radius: 4PX;
  background-color: #efeff9;
  width: 100%
}

.record-log-user-profile-img {
  height: 50px;
  width: 50px;
  padding: 0;
  border: 3px solid #fff;
  background-color: #fff;
}

.usr-btn {
  width: 100%;
}

.timeline {
  list-style: none;
  position: relative;
  width: 100%;
  margin-left: 30px;

  li {

    height: 100px;

    .timeline-entry {
      display: flex;
      // margin-top: 25px;

      .timeline-content {
        // position: relative;
        // margin-bottom: 5px;
        // margin-left: 55px;
      }

      .timeline-user-icon {
        // position: absolute;
        // margin-right: -25px;
      }
    }


  }
}

.timeline::before {
  background: #0051AC;
  width: 3px;
  content: " ";
  position: absolute;
  top: 25px;
  bottom: 25px;
  left: 23px;
  margin-bottom: 40px;
}

.btn-break {
  border-left: 1px solid #e3e3ee;
  height: 8px;
  margin: 0 4px 0;
}

.record-log-actions {
  display: flex;
  justify-content: flex-end;
}