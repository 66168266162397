@import '../../theme/variables';

.organisation-members {
    @apply grid p-12 mx-auto max-w-7xl;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 24rem 1fr;
    grid-template-rows: #{'max(24rem, 50vh) 1fr'};

    md-card-content {
        flex-grow: 1;
    }

    .md-title {
        word-break: break-all;
    }

    &--list {
        grid-column: 1 / span 2;
        grid-row: 1;

        md-card-header {
            @apply p-1;
        }

        md-card-header-text {
            display: flex;
            flex-direction: row;
        }

        .membership-status-segment {
            text-transform: uppercase;
            margin-left: 52px;
            display: flex;
            @apply rounded-lg;
            @apply border-grey-15;
            @apply border;
            @apply p-1;

            >div {
                @apply p-2;
                @apply rounded-lg;
                cursor: pointer;

                &.active {
                    @apply bg-primary;
                    @apply text-white;

                    .card-header-badge {
                        background-color: #E5F0FC;
                        @apply text-primary;
                    }
                }
            }

            .card-header-badge {
                @apply bg-grey-10;
                @apply text-grey-50;
                @apply border-none;
                display: inline-block;
                margin-left: 8px;
            }
        }
    }

    &--seats {
        margin-right: 1.5rem;
        color: #555C63;
        font-size: 12px;
    }
}



.membership-projects {


    &--no-projects {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h4 {
            color: #555C63;
            font-size: 0.875rem;
        }

        i {
            font-size: 1.5rem;
            color: #D9DBDE;
        }
    }
}

.organisation-role-radio {
    @apply p-4 flex flex-row cursor-pointer;
    &[disabled] {
        pointer-events: none;
        opacity: 0.33;
     } 
    &--checkbox {
        @apply bg-grey-5 rounded-full w-5 h-5 border-grey-20 border flex-shrink-0 mx-6 my-auto;

    }
    h5 {
        @apply text-grey-100 text-sm;
    }

    p {
        @apply text-grey-50 text-xs;
    }

    &.role-active {
        .organisation-role-radio--checkbox {
            @apply border-primary border-2 bg-primary;
        }
    }
}

.organisation-invite-header {
    @apply p-4 flex items-center justify-start bg-grey-10 border-grey-15 border-b;

    img {
        @apply w-8 h-8 mr-4;
    }

    h4 {
        @apply text-grey-100 font-bold;
    }

    p {
        @apply text-grey-50 text-xs;
    }
}

.organisation-invite-member-modal {
    height: 80vh;
    width: 60vw;
}

#organisation-members-table,#organisation-pending-table{
    width: 100%;
}