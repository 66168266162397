.organisation-dashboard {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 120px 1fr;

    @apply h-full grid p-12 max-w-7xl mx-auto;

    &.org-admin,
    &.org-owner {
        .organisation-dashboard--projects {
            grid-column: 1;
        }
    }

    md-card md-card-content {
        flex-grow: 1;
    }

    md-card-header i.fas {
        color: #D9DBDE;
        margin-right: 8px;
    }

    .md-title {
        color: #0E0F11;
    }

    &--header {
        grid-column: 1 / span 2;
        grid-row: 1;
        padding: 24px 0 24px 48px;
        position: relative;

        .md-avatar {
            width: 72px;
            height: 72px;
            margin-right: 28px;
        }

        h3 {
            font-weight: 600;
            font-size: 2rem;
        }

        p {
            color: #878F97;
            font-size: 12px;
        }

        .coreo-button {
            position: absolute;
            right: 8px;
            top: 8px;
        }
    }

    &--projects {
        grid-column: 1 / span 2;
    }

    &--card {
        background: white;
    }

    &--members {
        grid-column: 2;

        .user-avatar {
            width: 56px;
            height: 56px;
            border-radius: 100%;
            background: #E7E8EA;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            flex-shrink: 0;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            i {
                color: #878F97;
            }

            &.pending {
                opacity: 0.4;
            }
        }
    }
}