.record-content {
    max-width: 1170px;
    margin: 0 auto;
    display: block;

    h3 {
        margin-top: 0;
    }
}

section.record {
    ul {
        padding: 0 32px;
    }
}

.project-record {
    record-map {
        //     display: block;
        //     position: relative;
        height: 320px;
        //     // width: 500px;
        width: 100%;
    }

    md-card {
        margin-bottom: 16px;
    }

    .record-status-list {
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    }

    .record-user {
        padding: 12px 24px;

        img {
            width: 48px;
            height: 48px;
            margin-right: 12px;
            border-radius: 50%;
        }
    }

    .record-summary-list {
        // .list-item-label,
        // .list-item-value {
        //     padding-top: 24px;
        //     padding-bottom: 24px;
        // }
    }
}
