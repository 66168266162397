.project-pages {
    max-height: 100%;
    height: 100%;

    &>div {
        padding: 24px 48px;
    }
}

.project-pages-container {
    overflow-y: auto;
    padding-bottom: 0 !important;
    margin-right: -16px;
}

.pagebuilder-drag-placeholder {
    border-style: dashed;
    box-shadow: none;
    text-align: center;
    color: #888;
    border-width: 2px;
}

.sorting .pagebuilder-drag-placeholder {
    display: none;
}

.block-heading {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    line-height: 30px;
}

.layout-builder {
    width: 100%;
    max-width: 1280px;

    .ui-sortable-placeholder {
        border: 2px dashed #ddd;
        border-radius: 4px;
        visibility: visible !important;
    }

    overflow-y: auto;
}

.pagebuilder-pagelist {
    position: relative;

    .nav li a {
        padding: 7px 5px 7px 15px;
        cursor: pointer;
    }

    .page-edit {
        visibility: hidden;
        outline: none !important;
    }

    a:hover .page-edit {
        visibility: visible;
    }

    .active {
        background: #FFF;
    }

    .page-icon {
        width: 12px;
        display: inline-block;
        margin-right: 5px;
        text-align: center;
        font-size: 1.1em;
    }

    .page-route {
        font-weight: normal;
        font-size: 0.7em;

        i {
            opacity: 0.7;
            margin-right: 5px;
        }
    }
}

.pagebuilder-block {
    display: flex;
    align-items: center;
    flex-direction: column;

    i {
        vertical-align: middle;
        font-size: 20px;
        flex: 0 0 22px;
    }

    .block-name {
        hyphens: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    user-select: none;


}

.ui-sortable-helper {
    .pagebuilder-block {
        cursor: -webkit-grabbing;
    }
}

.block-sidebar {
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .block-sidebar__controls {
        flex: 0 0 64px;

        &>div {
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
                font-weight: bold;
            }
        }
    }

    .save-holder {
        margin: 8px 0;

        // margin-bottom: 20px;
        // min-height: 150px;
        button {
            // margin: 0;
            justify-content: center;
            align-items: center;

            &.md-primary i.fa {
                margin-right: 8px;
            }
        }
    }

    .page-settings {
        flex-grow: 1;
        overflow-y: auto;

        form {
            padding: 12px;
        }
    }

    .block-holder {
        flex-grow: 1;
        overflow-y: auto;
        padding: 0 6px;
        margin-right: -16px;
        width: 100%;

        .panel {
            margin: 0;
        }

        .block-container {
            display: inline-block !important;
            height: 94px;
            width: 50%;
            padding: 4px;

            .pagebuilder-block {
                height: 100%;
                width: 100%;
                padding: 6px 4px 4px;
                background-color: white;
                border: 1px solid rgba(151, 151, 151, 0.4);
                border-radius: 4px;

                &:hover {
                    cursor: -webkit-grab;
                    color: white;
                    background-color: rgb(51, 51, 51);
                }
            }
        }
    }
}

.route-parameters {
    line-height: 34px;
}

.page-parameters-help {
    opacity: 0.5;
}

.page-warning {
    line-height: 30px;
    color: rgb(255, 196, 0);
}

.block-item {
    padding: 10px 0px;
    border-bottom: 1px solid #EEE;
    display: flex;
    justify-content: stretch;

    .block-item-main {
        padding-left: 5px;
        flex-grow: 1;
    }

    button {
        width: 40px;
    }
}

.block-section {
    border-bottom: 1px solid #EEE;
}

.block-item-add {
    text-align: center;
    padding: 10px;
}

.block-card {
    md-card-header {
        padding: 8px 12px;
    }

    &.block-card-text {
        overflow: visible;
    }
}

.new-page-option-icon {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 52px;
    color: #bbb;
}

.new-page-option {
    margin: 12px 12px;
    flex: 1;
}

.project-pages {
    md-card:first-of-type {
        margin-top: 0;
    }

}

.project-pages-block-text-focus .note-toolbar {
    width: 100;
    z-index: 99999;
}

.preview-aside {
    iframe {
        margin-bottom: 12px;
        overflow: hidden;
        border-radius: 4px;
        border: 0;
        box-shadow: 0 3px 5px 0 rgba(41, 49, 52, 0.1);
    }
}

/* UNUSED STYLES 

.page-template-option {
    // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    opacity: 0.5;

    &:hover,
    &.page-template-option-selected {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
        opacity: 1;
    }
}

.page-template-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    flex: 0 0 100px;
}

.page-template-option-description {
    margin-top: 10px;
    text-align: center;
}

*/

.collection-page-form {
    input[type=checkbox] {
        flex-grow: 0;
        flex-basis: 40px;
        min-height: 32px;
    }

    label {
        font-weight: normal;
    }
}

.input-field {
    label {
        font-weight: 600
    }

    display: flex;
    align-items: center;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

// based on iphone 6/7/8 viewport
.mobile-viewport {
    margin: 0 auto;
    height: 736px;
    width: 414px;
}

.full-height {
    height: 100%
}

.note-editable {
    img {
        max-width: 100%;
        border: 0;
        display: block;
        max-height: 80%;
        margin: 0 auto;
    }
}

.note-editor {
    height: 100%;
}

.wysiwyg-container {
    width: 100%
}

.note-editable.panel-body {
    overflow-y: scroll;
}

.simple-image {
    padding: 20px 0;
}

.simple-image input {
    width: 100%;
    padding: 10px;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    outline: none;
    font-size: 14px;
}