media-item {
    display: inline-block;
    cursor: pointer;

    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    &:last-child {
        border-bottom: 0;
    }

    &.download {
        height: 40px;
    }

    // width: auto;
    // height: 240px;
    // border: 1px solid rgba(0, 0, 0, 0.2);
    // border-radius: 4px;
    // max-height: 240px;
    position: relative;
    text-align: center;

    .media-item-content a {
        margin-top: 18px;
        display: block;
    }

    p {
        position: absolute;
        z-index: 101;
    }

    p.info {
        margin-top: 40px;
        font-weight: bold;
    }

    p.path {
        opacity: 0.0;
        text-align: left;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        transition: opacity .3s;
    }

    video,
    img {
        max-width: 100%;
    }

    div {
        user-select: none;
        // width: 100%;
    }

    .media-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;

        a,
        button {
            opacity: 0.0;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
        }
    }

    .media-overlay.media-overlay-video {
        height: 50%;
        margin: auto 0;

        .media-overlay-buttons {
            top: 30%;
        }
    }

    .media-overlay-buttons {
        width: 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -75px;
        margin-top: -15px;
    }

    &:hover p.path {
        opacity: 1.0;
    }

    &:hover .media-overlay {
        // background-color: rgba(0, 0, 0, 0.6);

        a,
        button {
            display: inline-block;
            opacity: 1.0;
        }
    }

    .tooltip {
        height: 30px;
    }

    .ion-image {
        position: absolute;
        font-size: 40px;
        margin-top: -20px;
        margin-left: -20px;
        top: 50%;
        left: 50%;
    }

    &:hover .ion-image {
        opacity: 0.0;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }

    .media-item-footer {
        opacity: 0.7;
    }

}

.dropdownimage {
    width: auto;
    height: auto;
    max-width: 260px;
    max-height: 200px;
    // max-width: unset;
}