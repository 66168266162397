@import "../../theme/variables";
@import "../../theme/typography";

.project-collection-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: start; // max-width: calc(100vw - 30px);
}

// Amount of space used outside of the tab:
// navbar + padding + dropdown + footer + tabs + margin
$tab-external-space: 50px+30px+54px+51px+44px+20px;

.item-image {
    img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }

    i {
        border-radius: 50%;
        border: 1px solid grey;
        padding: 8px;
        font-size: 32px;
    }
}

.item-field-row {
    button {
        visibility: hidden;
    }

    &:hover button {
        visibility: visible;
    }

    th {
        cursor: move;
    }

    &.ui-sortable-helper {
        td,
        button,
        input,
        i {
            visibility: hidden;
        }
    }
}

li > a.project-collection-list-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    i {
        flex: 0 0 32px;
        height: 100%;
        width: 32px;
        cursor: move;
    }

    a {
        flex: 1 0 auto;
    }
}

ul.attribute-list {
    .fa-bars {
        cursor: move;
    }
}

ul.state-list {
    .item-value {
        width: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .item-key {
        width: 20%;
        text-align: right;
        opacity: 0.8;
    }
}

.edit-collection-attribute-modal {
    min-width: 400px;
}

.project-collection {
    ul.form-list li {
        @apply shadow-none;
    }
}

/* UNUSED STYLES

.collection-items {
    // 100vh - (available space + padding + button size + margins)
    max-height: calc(100vh - (#{$tab-external-space} + 20px + 54px + 20px));
    overflow-y: auto;

    &.geometry-list {
        // No button on geometry list
        max-height: calc(100vh - (#{$tab-external-space} + 20px + 20px));
    }
}

.collection-item-key {
    font-size: 0.8em;
    opacity: 0.6;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.items-tab {
    // Stops the items tab jumping when loading
    min-height: calc(100vh - (#{$tab-external-space}));
}

*/
