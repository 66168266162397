record-attributes-form {
    .ng-invalid {
        box-shadow: 0px 0px 0px 1.25px #fa4d56, 0px 0px 0px 6px rgba(250, 77, 86, 0.1);
        border-width: 0;
    }
    form.ng-invalid {
        box-shadow: none;
    }

    md-datepicker {
        @apply w-full block pr-0;
        padding-right: 0 !important;

        .md-datepicker-input-container {
            @apply w-full;
        }

        input.md-datepicker-input {
            border: 1px solid #beccdd;
            border-radius: 5px;
            padding: 8px;
            width: 100%;
            --tw-border-opacity: 1;
            border-color: rgba(217, 219, 222, var(--tw-border-opacity));
            min-width: 100%;
        }

        .md-datepicker-triangle-button.md-button.md-icon-button {
            @apply h-10 mr-3;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    .float-input::-webkit-outer-spin-button,
    .float-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    .float-input[type=number] {
    -moz-appearance: textfield;
    }

    ul.ui-sortable{
        grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
        @apply p-0;
    }
}
