/** FORM LIST PAGE **/
@import "../../theme/variables";

ul.form-list {
  position: relative;
  padding-bottom: 3px; //allow for final element's shadow

  // background: #FFF;
  .form-option-buttons {
    position: absolute;
    display: flex;
    top: 0px;
    bottom: 0px;
    right: 0px;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }

  li {
    position: relative;
    margin-top: 0px;
    background: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 20;

    a {
      display: flex;
      align-items: center;
      width: 100%;

      >div {
        flex-grow: 1;
        width: 50%;
      }

      .form-list-indent {
        width: 30px;
        color: rgba(0, 0, 0, 0.1);
      }

      .form-list-name {
        font-weight: bold;
        font-size: 1.1em;
      }

      .form-list-survey {
        opacity: 0.8;
      }

      .form-list-extra {
        text-align: center;
        display: flex;
      }

      .form-list-options {
        flex-grow: 0;
        width: 50px;
      }

      .form-list-warning {
        margin-right: 5px;
        opacity: 0.7;
      }
    }

    &:first-child {
      a {
        border-top: 0px;
      }
    }

    &.form-list-depth-0 {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;
    }

    &:not(.form-list-depth-0) {
      z-index: 17;

      .form-list-info {
        width: calc(50% - 90px);
      }

      .form-list-indent {
        width: 90px;
      }
    }

    &.form-list-depth-1 {
      z-index: 19;

      .form-list-info {
        width: calc(50% - 30px);
      }

      .form-list-indent {
        width: 30px;
      }
    }

    &.form-list-depth-2 {
      z-index: 18;

      .form-list-info {
        width: calc(50% - 60px);
      }

      .form-list-indent {
        width: 60px;
      }
    }
  }
}

.form-list-new {
  padding: 30px;
  text-align: center;
}

form.survey-form {
  margin-top: -10px;
  margin-bottom: -10px;
}

/** FORM BUILDER **/

.formbuilder {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;

  .form-header {
    background: #fff;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1);
    padding: 1px 0px;

    &.form-header-name {
      z-index: 11;
      box-shadow: none;
      padding: 1px 0px;

      .form-row {
        margin-bottom: 0px;
      }

      .form-input-helper {
        margin-bottom: 0px;
      }
    }

    &.form-header-title {
      z-index: 10;
      top: 0px;
      position: -webkit-sticky;
      position: sticky;
    }

    .form-inner {
      display: flex;
      flex-grow: 1;

      .form-row {
        flex-grow: 1;
      }
    }
  }

  .form-inner {
    margin-right: -1rem;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    min-width: 520px;
    position: relative;
  }

  .noeof {
    min-height: 800px;
    padding-bottom: 150px;
  }

  aside {
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 320px;

    // max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;

    .form-block-list {
      overflow-y: auto;
      flex-grow: 1;
    }
  }

  .form-aside-left {
    flex: 1 0 240px;
    max-width: 320px;

    .form-control {
      @apply rounded;
    }

    md-content {
      @apply px-0;
    }

    h4 {
      @apply bg-grey-5 text-grey-80 uppercase font-bold text-sm m-0;
      text-transform: uppercase;
      margin: 0;
      padding: 9px 12px;
    }

    .form-group {
      @apply px-2;
    }
  }

  .form-aside-right {
    flex: 2 0 420px;
    max-width: 420px;

    >div {
      overflow-y: auto;
      height: 100%;
    }

    h4 {
      @apply bg-grey-5 text-grey-80 uppercase font-bold text-sm m-0;
      text-transform: uppercase;
      margin: 0;
      padding: 9px 12px;
    }

    .form-control {
      @apply rounded;
    }
  }

  .form-fab {
    position: sticky;
    z-index: 100;
    width: 100px;
    // bottom: 50px; // 50% of screen + sidebar/2 + column/2
    // left: calc(50% + 230px/2 + 500px/2);
    right: 80px;
    top: 120px;

    @media (max-width: 1052px) {
      left: auto;
      right: 30px;
    }

    margin-left: 30px;
    margin-bottom: 15px;

    .btn {
      width: 110px;
      border-radius: 10px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);

      &:not([disabled]):active {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .back-button {
    padding-left: 10px;

    i {
      margin-right: 3px;
      opacity: 0.8;
    }
  }

  .form-title {
    font-size: 1.2em;
    height: 45px;
  }

  .form-block {
    background: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    position: relative; // margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 4px;

    &:first-of-type {
      margin-top: 15px;
    }

    &:hover {
      .form-block-remove {
        display: block;
      }
    }

    &.active {
      border-color: map-get($colors, primary-highlight);
    }

    &.errors {
      border-color: map-get($colors, red);
    }

    &-thankyou {
      .form-block-remove {
        position: absolute;
        right: 0;
      }
    }

    .form-block-inner {
      overflow: hidden;
      flex-grow: 1;
    }

    &.text .form-block-inner {
      overflow: visible;
    }

    &.nonvisible .form-block-inner {
      opacity: 0.6;
    }

    .form-block-remove {
      display: none;
    }

    .form-block-section-add {
      position: absolute;
      top: calc(100%);
      left: 0px;
      right: 0px;
      height: 15px;
      z-index: 501;
      cursor: pointer;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
      background: #fff;
      color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 150ms, top 150ms, height 150ms;

      i {
        margin-right: 5px;
      }

      &.ng-leave {
        display: none;
      }

      &:hover {
        top: calc(100% - 10px);
        height: 35px;
        opacity: 1;
      }
    }
  }

  .form-spacer {
    margin-bottom: 15px;
    display: flex;
    position: relative;

    .form-handle {
      background: rgba(#ecf0f5, 0.9);
    }

    .form-spacer-inner {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 15px;
      color: rgba(0, 0, 0, 0.3);
    }

    .form-handle-button {
      top: -5px;
    }

    .section-end {
      opacity: 0.5;
    }

    .block-heading {
      line-height: 75px;
    }

    .form-block-remove {
      display: none;
    }

    &:hover {
      .form-block-remove {
        display: block;
      }
    }
  }

  .form-handle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 5px 3px;
    // box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.1);
    width: 40px;
    cursor: move;

    .block-grip {
      color: rgba(0, 0, 0, 0.1);
    }

    .form-handle-button {
      height: 30px;
      position: absolute;
      top: 0px;
      right: 4px;

      .btn-link {
        color: rgba(0, 0, 0, 0.6);

        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }

  .section-divider-remove-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
  }

  .formbuilder-drag-placeholder {
    list-style: none;
    border-style: dashed;
    box-shadow: none;
    text-align: center;
    color: #888;
    border-color: #ddd;
    border-width: 2px;
    background: transparent;
    min-height: 64px;
    cursor: default;
    margin-bottom: 125px;

    .panel-body {
      padding: 10px 10px;
    }
  }

  .block-heading {
    text-align: center;
    padding: 5px 5px 5px 70px;
    line-height: 30px;
    cursor: move;

    .block-grip {
      font-size: 1.2em;
      opacity: 0.1;
    }

    .btn {
      background: transparent;
    }

    .form-button-holder {
      width: 70px;
      text-align: right;
    }
  }

  .form-layout-builder {
    z-index: 5;
    position: relative;
    padding: 24px 52px 50vh 52px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -1rem;

    &.dragging {
      min-height: 100%;
    }

    .ui-sortable-placeholder {
      height: 80px;
      border: 2px dashed #ddd;
      visibility: visible !important;
      background: transparent;
      margin-bottom: 15px;
      box-shadow: none;
    }

    &.form-layout-empty-overlay {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 800px;

      &.dragging {
        display: none;
      }

      .empty-icon {
        font-size: 8rem;
        padding: 32px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &.empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ui-sortable-placeholder {
        height: 100%;
        width: 100%;
      }
    }
  }

  .formbuilder-block {
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
      vertical-align: middle;
    }

    .block-name {
      flex-grow: 1;
      hyphens: auto;
    }

    .block-state {
      opacity: 0.8;
      font-size: 0.7em;
    }

    user-select: none;

    &:hover {
      cursor: -webkit-grab;
    }
  }

  .ui-sortable-helper {
    .formbuilder-block {
      cursor: -webkit-grabbing;
    }

    .form-block-remove.btn-link {
      display: none;
    }
  }

  .block-sidebar {
    position: -webkit-sticky;
    top: 15px;
    position: sticky;
    height: calc(100vh - 51px - 15px - 15px - 51px);
    display: flex;
    justify-content: stretch;
    flex-direction: column;

    .save-holder {
      margin-bottom: 5px;

      .save-status {
        line-height: 25px;
        font-size: 0.8em;
        text-align: center;
        visibility: hidden;
        cursor: default;

        &.visible {
          visibility: visible;
        }

        i {
          opacity: 0.7;
        }
      }
    }

    .block-holder {
      flex-grow: 1;
      overflow-y: auto;

      .panel {
        margin-top: 5px;
        margin-bottom: 5px;

        .panel-body {
          padding: 10px 10px;
        }
      }
    }

    .block-list-header {
      font-size: 0.9em;
      font-weight: bold;
      opacity: 0.8;
      padding-left: 3px;
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0px;
      }
    }
  }

  .block-item {
    padding: 10px 0px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: stretch;

    .block-item-main {
      padding-left: 5px;
      flex-grow: 1;
    }

    button {
      width: 40px;
    }
  }

  .block-section {
    border-bottom: 1px solid #eee;
  }

  .block-item-add {
    text-align: center;
    padding: 10px;
  }

  .form-add-options {
    text-align: center;
    display: flex;
    padding: 25px;
    justify-content: space-around;

    button {
      width: 130px;
      background: #fff;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      color: rgba(0, 0, 0, 0.7);
      padding: 10px;
      transition: background-color 100ms, box-shadow 150ms;

      i {
        color: rgba(0, 0, 0, 0.5);
        margin-right: 10px;
      }

      &:hover {
        background: #fbfbfb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      }

      &:active {
        background: #f7f7f7;
        outline: none;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .form-deleted {
    margin-top: 15px;
    background: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 8rem;
  }
}

.form-deleted-holder {
  // background: #ecf0f5;
  max-height: 70vh;
  overflow-y: auto;
}

.form-deleted-block {
  margin: 15px 0px;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;

  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  .form-deleted-options {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .form-deleted-block-inner {
    padding: 10px 15px;

    &.form-deleted-text {
      margin-right: 25px;
    }
  }

  .form-deleted-footer {
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    padding: 5px;

    .form-deleted-attribute {
      flex-grow: 1;
      padding: 10px 15px;
      font-size: 0.9em;

      .form-deleted-info {
        display: inline-block;
        margin-right: 30px;

        .form-deleted-key {
          color: rgba(0, 0, 0, 0.5);
        }

        .form-deleted-path {
          font-family: monospace;
        }
      }
    }
  }
}

.form-row {
  // margin-top: 15px;
  // margin-bottom: 15px;

  &.form-advanced-section {
    margin-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 15px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  &.form-summary {
    margin-top: -10px;
    padding-left: 10px;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.7);

    .form-summary-error {
      color: #cb2431;
    }
  }

  .form-description-add {
    display: block;
    margin-top: -5px;
    margin-bottom: -5px;
    padding: 0px 10px;
    color: rgba(0, 0, 0, 0.7);

    &:hover {
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
  }
}

.form-input {
  display: inline-block;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-right: 15px;
  position: relative;
  padding: 7px 10px;
  transition: background-color 100ms;

  label {
    display: block;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.6em;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0px;
  }

  input {
    border: 0px;
    background: transparent;
    outline: none;
    display: block;
    box-sizing: border-box;
    width: 150px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &.monospace {
      font-family: monospace;
    }
  }

  .form-description-remove {
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
    right: 0px;
    top: calc(50% - 17px);

    &:hover {
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;
    }

    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  &.form-input-title {
    input {
      font-size: 1.3em;
    }
  }

  &.error {
    label {
      color: #cb2431;
    }

    ::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 2px;
      background: #cb2431;
    }
  }

  &.readonly {
    input {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  &:hover:not(.readonly) {
    background: rgba(0, 0, 0, 0.07);
  }

  &.form-input-block {
    display: block;
    margin-right: 0px;

    input {
      width: 100%;
    }
  }

  &.form-input-small {
    input {
      width: 100px;
    }
  }

  &.ng-leave {
    display: none;
  }
}

.form-input-helper {
  margin: 2px 0px 15px 0px;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);

  &.error {
    color: #cb2431;
  }
}

.form-dropdown {
  .form-dropdown-label {
    display: block;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.6em;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  .caret {
    margin-left: 5px;
  }

  .form-dropdown-placeholder {
    opacity: 0.7;
  }

  .dropdown-toggle {
    transition: background-color 100ms;
    background: darken(#fff, 5%);
    text-align: left;

    &:hover,
    &:focus {
      background: darken(#fff, 10%);
    }

    &:active {
      box-shadow: none;
      background: rgba(0, 0, 0, 0.2);
    }

    i {
      margin-right: 10px;
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
    background: rgba(0, 0, 0, 0.2);
  }

  .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;

    >li {
      >a {
        cursor: pointer;
        padding: 7px 10px;

        i {
          margin-right: 10px;
        }

        &.active {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      &.divider {
        margin: 5px 0px;
      }
    }
  }
}

@mixin material-button-color($color) {
  background: $color;

  &:not([disabled]) {

    &:hover,
    &:focus {
      background: darken($color, 5%);
    }

    &:active {
      background: darken($color, 10%);
    }
  }
}

.btn-material {
  padding: 7px 14px;
  border: 1px solid transparent !important;
  border-radius: 4px;
  outline: none;
  transition: background-color 150ms, opacity 150ms, box-shadow 150ms;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

  &.btn-sm {
    padding: 5px 12px;
    font-size: 0.9em;
  }

  &.btn-lg {
    padding: 9px 15px;
    font-size: 1.1em;
  }

  &.btn-block {
    display: flex;
    align-items: center;

    .btn-text {
      flex-grow: 1;
    }
  }

  &.btn-default {
    @include material-button-color(#fff);

    &:not(.btn-full) {
      border: 1px solid rgba(0, 0, 0, 0.2) !important;
      box-shadow: none !important;
    }
  }

  &.btn-primary {
    @include material-button-color(#3c8dbc);
  }

  &.btn-success {
    @include material-button-color(#00a65a);
  }

  &.btn-danger {
    @include material-button-color(#dd4b39);
  }

  &.btn-info {
    @include material-button-color(#00c0ef);
  }

  &.btn-link {
    box-shadow: none !important;

    &:active {
      border: 0px;
      outline: none;
    }

    &.btn-link-dark {
      color: darken(#fff, 50%);

      &:hover,
      &:focus {
        color: darken(#fff, 90%);
      }
    }
  }

  &:not([disabled]) {
    &:active {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      outline: none;
    }
  }

  &.btn-chip {
    @include material-button-color(#f2f2f2);
    box-shadow: none;

    &:active {
      box-shadow: none;
    }
  }

  &:not(.btn-icon-only) {

    .btn-icon,
    .fas,
    .far {
      margin-right: 10px;
    }
  }
}

.form-breadcrumb {
  padding: 15px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.form-aside-controls {
  >div {
    text-transform: uppercase;
    height: 64px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-bottom-width: 1px;
    border-bottom-color: map-get($colors, lighter);
    border-bottom-style: solid;
    background: map-get($colors, background);
    border-right: 1px solid map-get($colors, lighter);
    opacity: 0.6;
    cursor: pointer;

    &.active {
      opacity: 1;
      color: map-get($colors, primary);
      border-bottom-width: 4px;
      border-bottom-color: map-get($colors, primary);
      background: #fff;
    }
  }

  md-icon {
    margin: 0;
    font-size: 20px;
  }
}

.form-question-types {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.form-question-type {
  flex: 0 0 50%;
  height: 80px;
  padding: 8px;
  text-align: center;
  list-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: opacity 0.3s linear;

  &[disabled] {
    opacity: 0.4;
    cursor: none;
    pointer-events: none;
  }

  &:hover .question {
    cursor: -webkit-grab;
    color: white;
    background-color: rgb(51, 51, 51);
  }

  .question {
    cursor: pointer;
    border: 1px solid map-get($colors, lighter);
    border-radius: 4px;
    display: block;
    width: 100%;
    height: 100%;
    color: #000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    white-space: pre-line;
    overflow: hidden;
    font-size: 12px;
    padding: 2px;

    i {
      font-size: 20px;
    }

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
}

.form-question-placeholder {
  height: 80px;
}

.forms-trash-button {
  // position: absolute;
  // display: flex;
  // top: 0px;
  // bottom: 0px;
  // right: 32px;
  // flex-direction: column;
  // justify-content: center;

  button {
    margin: 0;
    padding: 0;
  }
}

.forms-indicators {
  right: 75px;
  position: absolute;
  justify-content: space-around;
  align-items: center;
  display: flex;
  height: 100%;
}

.forms-indicator {
  opacity: 0.7;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
}

.form-options {
  width: 100px;
  height: 100%;
  position: absolute;
  right: 0;
  justify-content: space-around;
  display: flex;

  div {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.forms-handle {
  // position: absolute;
  // top: 0px;
  // bottom: 0px;
  // right: 0px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // width: 40px;
  // padding-left: 16px;
  // padding-right: 16px;
  cursor: move;

  .block-grip {
    color: rgba(0, 0, 0, 0.1);
  }
}

.forms-container {
  flex-grow: 1;
}

.form-settings-checkbox {
  float: right;
}