@import "../../theme/variables";

project-records-table {
    font-size: 0.9em;
}

.nbn-modal-input {
    padding-bottom: 7px;
}

.modal-form-header {
    text-align: left;
}

.modal-table-header {
    text-align: center;
}

.modal-table-field {
    text-align: center;
}

.modal-form-subheading {
    color: gray;
}

.project-records-container {
    @apply absolute flex left-0 right-0 bottom-0 top-0 overflow-hidden;
}

.project-records-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; // overflow: hidden;
}

.project-records-inner.map {
    .project-records-table {
        height: 0%;
        flex-basis: 0%;
    }

    .project-records-map {
        height: 100%;
        flex-basis: 100%;
    }
}

.project-records-inner.table {
    .project-records-table {
        height: 100%;
        flex-basis: 100%;
        min-height: 100%;
    }

    .project-records-map {
        height: 0%;
        flex-basis: 0%;
    }
}

$statusBarHeight: 48px;

.project-records-statusbar {
    flex: 0 0 $statusBarHeight;
    height: $statusBarHeight;
    width: 100%;
    background: white;
    transition: flex-basis 0.4s;

    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;

    &.filtering {
        flex-basis: 180px;
    }

    .btn-material {
        height: $statusBarHeight;
        border: 0;
        border-radius: 0;
        outline: none;

        i.fas {
            margin-right: 0;
        }
    }

    .btn-group {
        .btn {
            border-top: none !important;
            // border-bottom: none !important;
            outline: none;
        }
    }

    project-records-chips {
        flex: 1 0 auto;

        md-chips {
            md-chip {
                font-size: 12px;
                margin: 0 2px;
            }

            .md-chips {
                box-shadow: none;
                user-select: none;
                outline: none;
                height: 100%;
                padding: 0 12px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    md-toolbar {
        height: $statusBarHeight;
        min-height: $statusBarHeight;
        background-color: transparent;
        color: black;
        border-right: 1px solid #e6ecf3;

        .md-toolbar-tools {
            padding-left: 0;
            height: $statusBarHeight;
            overflow: hidden;

            .md-button {
                margin-left: 0;
                margin-right: 0;
                display: flex;
                width: auto;
                color: map-get($colors, primary);
                text-transform: none;
                font-weight: bold;
            }

            .coreo-button {
                color: white;
            }

            md-menu-bar {
                padding: 0;
            }
        }
    }

    md-content {
        display: flex;
        flex-direction: row;
        height: 100%;

        .btn-group {
            flex: 0 0 132px;
        }
    }
}

.project-records-statusbar-label {
    line-height: $statusBarHeight;
    padding: 0 4px;
    font-size: 11px;
    font-weight: 700;
}

.project-records-main-container {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    max-width: 100%;
    background: #888;
}

.project-records-main {
    // flex: 1 0 auto;
    width: 100%;
    background: white; // border-top: 1px solid rgba(40, 40, 40, 0.4);
    display: flex;
    flex-direction: column; // overflow: hidden;
    transition: transform 0.3s;
}

.project-records-container::-webkit-scrollbar {
    display: none;
}

.project-records-map {
    flex: 1;
    outline: none;
    position: relative; // transition: height 0.2s;
}

$sidebarWidth: 320px;

.project-records-table {
    flex: 1;
    overflow: hidden;
    position: relative;

    project-records-table {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    transition: margin-left 0.3s;

    &.sideopen {
        margin-left: $sidebarWidth;
    }
}

.project-records-forms {
    @apply w-full bg-white h-10 flex items-center flex-grow-0;
    flex-basis: 40px;

    span {
        @apply text-grey-50 uppercase font-semibold text-sm leading-3 px-4 text-center cursor-pointer h-full;
        line-height: 40px;

        &.active {
            @apply text-primary border-b border-primary;
        }
    }
}

project-records-table {
    div[ng-table-pagination="params"] {
        padding: 0 20px;
    }
}

.project-records-sidebar--toggle {
    @apply absolute top-0 h-10 w-8 bg-white left-0 z-50 cursor-pointer;
    border-bottom-right-radius: 4px;
    transition: transform 0.3s;
    transform: translateX($sidebarWidth);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;

    md-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.closed {
        transform: translateX(0);
    }
}

.project-records-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: $sidebarWidth;
    transform: translateX(-$sidebarWidth);
    background-color: white;
    transition: transform 0.3s;
    will-change: transform;
    overflow: hidden;

    md-content {
        height: 100%;
        margin-right: -100%;
        padding-right: 100%;
        overflow: auto;
        background: white;
    }

    &.project-records-sidebar-large {
        width: $sidebarWidth * 1.5;
        transform: translateX($sidebarWidth * -1.5);
    }

    &.active {
        transform: translateX(0);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
        z-index: 49;
    }

    .project-records-sidebar-toolbar {
        position: sticky;
        top: 0;
        background: white;
        margin-bottom: 12px;
    }
}

.project-records-content {
    position: absolute;
    top: 48px;
    right: 0;
    bottom: 0;
    width: $sidebarWidth;
    transform: translateX($sidebarWidth);
    background-color: white;
    transition: transform 0.3s;
    will-change: transform;
    z-index: 9999;

    &.active {
        transform: translateX(0);
    }

    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.project-records-filter {
    form {
        @apply pt-4;
        width: $sidebarWidth;
    }

    md-datepicker {
        .md-datepicker-input-container {
            width: 100%;
        }

        input {
            border: 1px solid #beccdd;
            border-radius: 5px;
            padding: 8px;
            width: 100%;
            @apply border-grey-20;
        }
    }

    .coreo-form-group {
        @apply px-4;
    }

    md-content {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        padding: 0;
    }

    .filter-attribute-select {
        md-checkbox {
            margin-bottom: 12px;

            &:first-of-type {
                margin-top: 12px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    md-input-container {
        margin-top: 0;
    }

    .form-group-date {
        align-items: center;

        md-input-container {
            margin-bottom: 0;
            align-items: center;
            display: flex;
        }
    }

    .md-select-placeholder {
        color: rgb(117, 117, 117);
        font-size: 14px;
    }

    md-autocomplete {
        padding-right: 0;
    }

    .map-filtering {
        @apply bg-grey-10 rounded-2xl mx-4 px-2 py-1 mb-2 text-grey-50 text-xs font-normal border border-grey-10 flex justify-between cursor-pointer;

        &.active {
            @apply border-primary text-primary;
        }
    }
}

.project-records-sidebar-header {
    width: $sidebarWidth;
    z-index: 999999;
    @apply bg-grey-5 px-4 py-2 text-grey-50 items-center justify-between flex font-bold uppercase text-xs h-10;
}

.project-records-selected {
    overflow-y: auto;
    overflow-x: hidden;

    md-toolbar {
        flex: 1 0 auto;
    }
}

.project-records-sidebar--right {
    right: 0;
    left: unset;

    transform: translateX(480px);

    &.active {
        transform: translateX(0);
    }
}

md-menu-item {
    i.fas {
        margin-right: 8px;
    }
}

.project-records--date-container {
    position: relative;

    &:first-of-type {
        margin-right: 16px;
    }

    button {
        position: absolute;
        right: 0px;
        padding-right: 0 !important;
        font-size: 18px;
        color: rgb(51, 51, 51);
    }
}

.project-records-record-summary {
    padding: 12px 24px;
    font-size: 13px;

    label,
    h3,
    md-select {
        margin: 0;
    }

    .project-records-record-verification {
        flex: 0 0 32px;

        label {
            line-height: 38px;
        }
    }

    .project-records-record-stateupdate {
        opacity: 0;
        transition: opacity 0.3s linear;
        font-weight: bold;
        text-align: right;

        &.active {
            opacity: 1;
        }
    }

    .project-records-record-summary__header {
        flex: 0 0 38px;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
    }

    .project-records-record-summary__footer {
        flex: 0 0 32px;
    }
}

.form-group-content.surveys {
    .md-label {
        margin-top: 1px;
    }

    .form-color {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: top;
    }
}

.project-records-number-input::-webkit-outer-spin-button,
.project-records-number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.project-records-number-input {
    -moz-appearance: textfield;
}

.id-filter-clear {
    @apply absolute right-4 bg-white top-3 cursor-pointer text-grey-50 transition-opacity pointer-events-none opacity-0;

    &.active {
        @apply opacity-100 pointer-events-auto;
    }
}

.recorder-autocomplete {
    .md-autocomplete-suggestion {
        @apply h-auto py-2 whitespace-normal border-b border-grey-15 leading-8;
        &:hover {
            @apply bg-grey-10;
        }
    }
}
