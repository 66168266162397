.signup-checkbox {
    label {
        display: block;
    }

    input {
        vertical-align: bottom;
    }
}

.password-type-toggle {
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
    @apply text-grey-80;
    @apply cursor-pointer;
}

.signup--complete {
    background: rgba(14, 15, 17, 0.4);

    .fa-spinner {
        @apply text-9xl;
        animation-duration: 1.5s;
    }

    .coreo-button {
        @apply mx-auto block mt-4;
        max-width: 120px;
    }
}

.signup-username-input-field {
    text-transform: lowercase;
}

#coreo-signup-icon{
    @media (min-width: 768px){
        @apply hidden;
    }
}