.project-image {
    margin: 20px auto;
    display: block;
    max-width: 100%;
    max-height: 100px;
}

.inline-input {
    display: inline-block;
    width: 120px;
    text-align: right;
}

.subdomain-input {
    border: 0;
    border-bottom: 1px solid #ccc;
    text-align: center;
    padding: 4px 0;
    margin: 0 9px;
}

.project-records-autocomplete[disabled] {
    background-color: lightgrey;
}
