.rule-item {
    padding: 5px 0px;
    border-bottom: none;

    .close {
        font-size: 1em;
    }

    .form-group {
        margin-left: -2px;
        margin-right: 2px;
        margin-bottom: 0px;

        >div,
        ng-container>div {
            padding: 2px;
        }
    }

    .rule-warning {
        display: flex;
        align-items: center;
        opacity: 0.5;
        width: 18px;
        text-align: center;
        line-height: 30px;
    }

    .rule-no-options {
        padding-left: 15px !important;
        height: 38px;
        display: flex;
        align-items: center;
    }

    .rule-no-question {
        opacity: 0.8;
        height: 38px;
        display: flex;
        align-items: center;
    }

    +.block-item-add {
        padding-top: 20px;
    }
}

.rule-all {
    margin: 10px 25px 10px;

    select {
        margin: 0px 5px;
        display: inline-block;
        vertical-align: middle;
        width: auto;
    }
}