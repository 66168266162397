.overviewTable {
    border-spacing: 20px;
    border-collapse: separate;
    width: 100%;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.overview {
    display: inline;
    white-space: nowrap;
}

.request-overview {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    text-align: center;

    dt,
    dd {
        display: inline-block;
    }

    dl {
        margin-bottom: 0;
    }

    dt {
        margin-right: 8px;
    }

    i {
        min-height: 64px;
    }
}

.request-overview__item {
    min-height: 60px;
    padding: 20px;
}

.request-raw {
    font-size: 11px;
}

.requests-table {
    font-size: 11px;
}

.request-body {
    font-size: 11px;
}