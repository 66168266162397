@import "../../theme/variables";

.project-dashboard {
    &.admin {
        grid-template-columns: 1fr 2fr;
    }

    &:not(.admin) {
        grid-template-columns: 1fr;
    }

    grid-template-rows: 84px 1fr;
    height: 100%;

    @screen sm {
        @apply p-12 grid;
    }

    &__title {
        grid-column: 1;
        grid-row: 1;

        h4 {
            @apply font-bold text-grey-80 text-xl;
        }
    }

    ul {
        @apply list-none;

        li {
            @apply text-grey-80 uppercase px-2 py-5 flex justify-between;
        }
    }

    &__records {
        &.admin {
            grid-column: 2;
            grid-row: 1 / span 3;
        }

        &:not(.admin) {
            grid-column: 1;
            grid-row: 2 / span 3;
        }

        &--period {
            @apply border border-grey-15 rounded h-8 mr-4 flex items-center;

            span {
                @apply uppercase text-grey-50 text-xs font-semibold h-6 rounded cursor-pointer leading-6 px-2;

                &.active {
                    @apply bg-primary text-white;
                }
            }

            &.disabled {
                @apply opacity-30 pointer-events-none;
            }
        }
    }

    md-card {
        // md-card-header {
        //     @apply p-0;
        // }

        md-card-content {
            @apply py-0;
        }
    }

    canvas {
        max-width: 100%;
        // width: auto;
        // height: 100%;
        // max-height: 340px;
        // min-height: 340px;
        // padding: 10px 25px;
    }

    .project-record-activity-loading-spinner {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        background: rgba(122, 122, 122, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    md-list-item {
        border: 0;
    }

    &__build {
        md-card-header {
            @apply px-3 py-3;
        }
    }

    &.locked {
        .project-dashboard__title {
            grid-column: 1 / span 2;
        }
        .project-dashboard__records {
            grid-row: 2;
            grid-column: 1 / span 2;
        }
    }
}

.project-dashboard-tasks {
    @apply mx-auto max-w-screen-xl;

    md-card-header {
        @apply px-3;
    }

    md-card-header-text {
        @apply px-0;
    }

    md-card-content {
        @apply p-0;
    }

    li i.fas {
        @apply text-grey-80;
    }

    li.open {
        background-color: #f8fcff;

        .fa-chevron-down {
            @apply rotate-180;
        }

        section {
            @apply h-16;
        }
    }
}

.project-dashboard-tasks + .project-dashboard {
    @apply pt-4;
}

.coreo-button.dashboard--records {
    max-height: 52px;
}

.coreo-button.dashboard--members {
    margin-left: auto;
    max-height: 52px;
}
