.rules-summary {
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 10px;
    padding-bottom: 20px;
    padding-top: 0px;
    font-size: 0.9em;

    .rule-summary {
        opacity: 0.9;
        font-size: 0.9em;
        margin-left: 35px;
    }

    &.rules-summary-text {
        margin-top: 0px;
    }
}